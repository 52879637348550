import { MaskDirective } from './directives/mask';
import { SharedModule } from './shared/shared.module';
import { CustomSerializer } from './core/store/serializers/router.serializer';
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { DynamicComponentLoaderModule } from './shared/dynamic-component-loader/dynamic-component-loader.module';
import { environment } from './../environments/environment';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreRouterConnectingModule, RouterStateSerializer } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AppEffects } from './core/store/effects/app.effects';
import { AppReducers } from './core/store/app.reducers';
import { metaReducers } from './core/store/localStorage.config';
import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { NotifyComponent } from './shared/notify/notify.component';
import { ModalComponent } from './shared/modal/modal.component';
import { PreloadComponent } from './shared/preload/preload.component';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { CoreModule } from './core/core.module';

import { createTranslateLoader } from './core/language';


@NgModule({
  declarations: [
    AppComponent,
    PreloadComponent,
    ModalComponent,
    NotifyComponent
  ],
  imports: [
    SharedModule,
    StoreModule.forRoot( AppReducers, { metaReducers } ),
    EffectsModule.forRoot( AppEffects ),
    StoreRouterConnectingModule.forRoot(),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
      // serialize: true
    }),
    DynamicComponentLoaderModule,
    DynamicComponentLoaderModule.forRoot( [] ),
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: (createTranslateLoader),
          deps: [HttpClient]
      },
      isolate: true
    }),
    BrowserModule,
    AppRoutingModule,
    CoreModule,
  ],

  providers: [
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    { provide: RouterStateSerializer, useClass: CustomSerializer },
  ],
  exports: [
    PreloadComponent,
    ModalComponent,
    NotifyComponent,
  ],
  entryComponents: [
    PreloadComponent,
    ModalComponent,
    NotifyComponent,
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
