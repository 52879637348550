import { FormValidations } from './../utils/form-validations';
import { ModalService } from './modal.service';
import { DynamicComponentLoader } from './../../shared/dynamic-component-loader/dynamic-component-loader.service';
import { PreloadComponent } from './../../shared/preload/preload.component';
import { Component, OnInit, Input, Output, EventEmitter, ComponentRef, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { BaseFormComponent } from './../../core/base-form.component';

import { Store } from '@ngrx/store';
import { AppStateUnion } from './../../core/store/states';

declare const window: any;
declare const document: any;
declare const $: any;


@Component({
  selector: 'am-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent extends BaseFormComponent implements OnInit, OnDestroy {

  @Input() id: string = null;
  @Input() title: string = null;
  @Input() body: string = null;
  @Input() filename: string = null;

  @Output() confirm = new EventEmitter<any>();
  @Output() cancel = new EventEmitter<any>();

  private loader;

  public cmpRef: ComponentRef<Component>;

  public data: any;

  public labelName;
  public labelEmail;
  public labelPhone;
  public labelConfirm;
  public labelCancel;

  constructor(
    _translate: TranslateService,
    _store: Store<AppStateUnion>,
    private _formBuilder: FormBuilder,
    private _modalService: ModalService,
    private _dynamicComponentLoader: DynamicComponentLoader
  ) {
    super(_translate, _store);
  }

  ngOnInit() {
    super.ngOnInit();
    this.loadFormLanguage();
    this.loadData();
  }

  onConfirm( event ) {
    this.confirm.emit(true);
  }

  onCancel( event ) {
    this.cancel.emit(true);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  _confirm = {
    'pt-br': 'Confirmar',
    en: 'Confirm',
    es: 'Confirmar',
    fr: 'Confirmer'
  }

  _cancel = {
    'pt-br': 'Cancelar',
    en: 'Cancel',
    es: 'Cancelar',
    fr: 'Annuler'
  }


  name = {
    'pt-br': 'Nome',
    en: 'Name',
    es: 'Nombre',
    fr: 'Nom'
  }
  email = {
    'pt-br': 'E-mail',
    en: 'E-mail',
    es: 'E-mail',
    fr: 'E-mail'
  }
  phone = {
    'pt-br': 'Telefone',
    en: 'Phone',
    es: 'Teléfono',
    fr: 'Téléphone'
  }

  loadFormLanguage(){
    this.labelName = this.name[this.language];
    this.labelEmail = this.email[this.language];
    this.labelPhone = this.phone[this.language];
    this.labelConfirm = this._confirm[this.language];
    this.labelCancel = this._cancel[this.language];
  }

  setFormBuilder() {
    this.form = this._formBuilder.group({
      name: [ '' , Validators.compose([
        Validators.required,
        Validators.minLength( 2 ),
        Validators.maxLength( 50 )
      ]) ],
      mail: [ '' , Validators.compose([
        Validators.email,
        Validators.required,
        Validators.minLength( 2 ),
        Validators.maxLength( 50 )
      ]) ],
      phone: [ '' , Validators.compose([
        Validators.required,
        Validators.minLength( 2 ),
        Validators.maxLength( 50 )
      ]) ],
    });
  }

  loadData(){
    this.setData();
  }

  setData( data: any = {} ) {
    this.setFormBuilder();
  }

  submit( ) {
    const element = document.querySelector('.modal');
    this.loader = this._dynamicComponentLoader.appendComponentTo( PreloadComponent, element);
    const data = {
      filename: this.filename,
      name: this.form.controls.name.value,
      mail: this.form.controls.mail.value,
      phone: this.form.controls.phone.value,
      mailTo: 'contato@primasea.com.br',
    }

    console.log('enviando form >>> ', JSON.stringify(data));

    var xmlhttp = new XMLHttpRequest();   // new HttpRequest instance
    var theUrl = "https://planmkt.com.br/clientes/primasea/mail/index.php";
    xmlhttp.open("POST", theUrl);
    xmlhttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    xmlhttp.send(JSON.stringify(data));

    setTimeout( () => {
      this.download_file( `../../../assets/pdf/recommendation/${this.language}/${this.filename}`, this.filename);
      this.form.reset();
      this.form.clearAsyncValidators();
      this.loader.element.remove();
      this.cancel.emit(true);
    }, 3000 );

    return true;

    // this._modalService.sendMessage(data).subscribe( ( data: any ) => {
    //   this.loader.element.remove();
    //   this.clearForm();
    //   if( data.message === 'ok' ) {
    //     // window.open( `../../../assets/pdf/recommendation/${this.filename}`,'_blank');
    //     this.download_file( `../../../assets/pdf/recommendation/${this.language}/${this.filename}`, this.filename);
    //   }
    //   this.cancel.emit(true);
    // });

  }

  load(){ };

  download_file(fileURL, fileName) {
    // for non-IE
    if (!window.ActiveXObject) {
        var save = document.createElement('a');
        save.href = fileURL;
        save.target = '_blank';
        var filename = fileURL.substring(fileURL.lastIndexOf('/')+1);
        save.download = fileName || filename;
	       if ( navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) && navigator.userAgent.search("Chrome") < 0) {
				document.location = save.href;
        // window event not working here
			}else{
		        var evt = new MouseEvent('click', {
		            'view': window,
		            'bubbles': true,
		            'cancelable': false
		        });
		        save.dispatchEvent(evt);
		        (window.URL || window.webkitURL).revokeObjectURL(save.href);
			}
    }

    // for IE < 11
    else if ( !! window.ActiveXObject && document.execCommand)     {
        var _window = window.open(fileURL, '_blank');
        _window.document.close();
        _window.document.execCommand('SaveAs', true, fileName || fileURL)
        _window.close();
    }
  }

}

export interface IModal {
  modalId: string;
}
