import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { Swipe }  from './bootstrap-swipe-carousel';

import { DynamicComponentLoader } from './../dynamic-component-loader/dynamic-component-loader.service';
import { MeetComponent } from './../../modules/site/home/meet/meet.component';
import { ImageComponent } from './../image/image.component';

declare const $: any;

@Component({
  selector: 'am-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit {

  @ViewChild('carousel', {static: true}) _carousel !: ElementRef;
  @ViewChild('slot', {static: true}) slot !: ElementRef;

  @Input('images') images: Array<any>;
  @Input('data') data: Array<any>;
  @Input('component') component?: string = null;
  @Input('theme') theme?: string = '';
  @Input('interval') interval?: number | boolean = 2000;
  @Input('id') id: string = null;

  constructor(
    private _dynamicComponentLoader: DynamicComponentLoader
  ) { }

  ngOnInit() {
    const time = setInterval( () => {
      const element = document.querySelector( `#${this.id} .carousel-item` );
      if( element ){
        clearInterval( time );
        setTimeout( () => { this.mount(); }, 1000)
      }
    } );
  }

  mount() {
    this.data.forEach( ( item, index )=> {
      const el = document.querySelector(`#${this.id} .item-${index}`);
      if( !el ) return;
      switch ( this.component ) {
        case 'MeetComponent':
          this._dynamicComponentLoader.appendComponentTo( MeetComponent, el, item);
          break;
        case 'ImageComponent':
          this._dynamicComponentLoader.appendComponentTo( ImageComponent, el, item);
          break;
      }
    });
    setTimeout( () => {
        new Swipe(this._carousel.nativeElement);
    }, 5)
  }

}
