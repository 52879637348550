import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { environment } from '../../environments/environment';

import { ApolloModule as _ApollModule, Apollo } from 'apollo-angular';
import { ApolloLink } from 'apollo-link';
import { HttpLinkModule, HttpLink } from 'apollo-angular-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { onError } from 'apollo-link-error';

@NgModule({
  imports: [
    HttpClientModule,
    _ApollModule,
    HttpLinkModule
  ]
})
export class ApolloModule {

  constructor(
    private apollo: Apollo,
    private httpLink: HttpLink
  ) {
    const linkHttp = httpLink.create({
      uri: `${environment.uri}${environment.gql}`,
    });

    const linkError = onError(({ graphQLErrors, networkError }) => {
      if ( graphQLErrors ) {
        graphQLErrors.map(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          ),
        );
      }
      if ( networkError ) {
        console.log(`[Network error]: ${networkError}`);
      }
    });

    const authLink = new ApolloLink((operation, forward) => {
      // Retrieve the authorization token from local storage.
      const token = localStorage.getItem('x-access-token');
      const tokenTemp = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6MjQsIm5hbWUiOiJqaGVmdGVzdGUiLCJleHAiOiIyMDE5LTExLTI2VDE3OjQ1OjE2LjI2NVoifQ.Gj7HG5MFAgVD66_yTyj0J1AYUOlqmjxp8Ls_xOgdSp4';

      // Use the setContext method to set the HTTP headers.
      operation.setContext({
        headers: {
          'x-access-token': token || tokenTemp
        }
      });

      // Call the next link in the middleware chain.
      return forward(operation);
    });

    apollo.create({
      link: ApolloLink.from([ linkError, authLink, linkHttp ]),
      cache: new InMemoryCache({
        // addTypename: false
      })
    });
  }
}
