import { CustomFormsModule } from './forms/customforms.module';
import { TableComponent } from './table/table.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { CarouselComponent } from './carousel/carousel.component';
import { PaginatorComponent } from './paginator/paginator.component';
import { PageHeaderComponent } from './page-header/page-header.component';

import { MaskDirective } from './../directives/mask';
@NgModule({

  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    CustomFormsModule,
  ],
  declarations: [
    CarouselComponent,
    PaginatorComponent,
    TableComponent,
    PageHeaderComponent,
    MaskDirective,
  ],
  exports: [
    CommonModule,
    FormsModule,
    CustomFormsModule,
    RouterModule,
    CarouselComponent,
    PaginatorComponent,
    TableComponent,
    PageHeaderComponent,
    MaskDirective,
  ],
  entryComponents: [
    PaginatorComponent,
  ],
})
export class SharedModule { }
