import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'am-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss']
})
export class ImageComponent implements OnInit {

  @Input() class?: string = 'd-block';
  @Input() src: string = '';
  @Input() alt?: string = '';
  @Input() width?: string = '100%';
  @Input() height?: string = '100%'

  constructor() { }

  ngOnInit() { }

}
