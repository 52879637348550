import { NgModule, Optional, SkipSelf } from '@angular/core';
import { registerLocaleData, CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';


/** HTTP **/
import { HttpClientModule } from '@angular/common/http';
import { ApolloModule } from './apollo.module';

/** I18N **/
import localePtBr from '@angular/common/locales/pt';
registerLocaleData(localePtBr, 'pt');

import { MeetComponent } from './../modules/site/home/meet/meet.component';
import { ImageComponent } from './../shared/image/image.component';

// import { ImageComponent } from './image/image.component';
// import { ModalComponent } from './modal/modal.component';

@NgModule({
  imports:[
    CommonModule,
    RouterModule,
  ],
  declarations: [
    MeetComponent,
    ImageComponent,
  ],
  exports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    ApolloModule,
    MeetComponent,
    ImageComponent,
  ],
  entryComponents: [
    MeetComponent,
    ImageComponent,
  ],
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentMolule: CoreModule
  ) {
    if ( parentMolule ) {
      // console.error(`O modulo ja foi carregado em outro modulo, utilize-o apenas no CoreModule`);
    }
  }
}
