import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'am-meet',
  templateUrl: './meet.component.html',
  styleUrls: ['./meet.component.scss']
})
export class MeetComponent implements OnInit {

  @Input() link = '';
  @Input() image = '';
  @Input() title = '';
  @Input() text = '';

  constructor() { }

  ngOnInit() { }

}
