import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { startAppExternal } from './../../helper/loadJS';

@Component({
  selector: 'am-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit {

  @Input() count: number = 1;
  @Input() link: string = '';
  @Input() query: string = 'pagina';
  @Input() current: number = 1;
  @Input() itemsPerPage: number = 1;

  items: Array<number>
  hasPrev: boolean;
  hasNext: boolean;

  constructor( private _route: ActivatedRoute ) { }

  ngOnInit() {
    const totalPage = Math.floor(this.count / this.itemsPerPage);
    this.items = Array.from({length: totalPage}, (v, k) => k + 1);
    this._route.queryParams.subscribe( ( queryParams ) => {
      this.current = queryParams['pagina'] || 1;
      this.hasPrev = !( this.current <= 1 );
      this.hasNext = !( this.current >= this.count );
      startAppExternal();
    });
  }

  getParams( param ){
    return { [this.query]: param }
  }

}
