import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from './../../../environments/environment';

import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor(
    private http: HttpClient,
  ) { }

  sendMessage( data ){
    const tokenTemp = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6MjQsIm5hbWUiOiJqaGVmdGVzdGUiLCJleHAiOiIyMDE5LTExLTI2VDE3OjQ1OjE2LjI2NVoifQ.Gj7HG5MFAgVD66_yTyj0J1AYUOlqmjxp8Ls_xOgdSp4';
    // const options = {
    //   headers: {
    //     "x-access-token": tokenTemp,
    //     "Content-Type": "application/json",
    //     "Access-Control-Allow-Origin": '*',
    //   },
    // };

    const options = this.optionsHeaders();

    return this.http
    .post(`https://planmkt.com.br/clientes/primasea/mail/index.php`, data, options )
    .pipe(
      map(dados => dados)
    )
  }

  optionsHeaders() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Methods':'POST',
          'Access-Control-Allow-Headers': 'Content-Type'
      })
    }

    return httpOptions;
  }
}
