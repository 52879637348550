declare const window: any;
declare const $: any;
const time:number = 900;

export const startAppExternal = () => {
}
/*
  $('html, body').animate({
    scrollTop: 0
  }, time/2);
  setTimeout(()=>{
    window.StartApp();
    window.Quantity();
    window.slideshow();
    window.Carousel();
    window.Masonry();
    window.StarRating();
    window.Tabs();
  }, time);
}
*/
